<template>
  <div class="news-page">
    <div class="news-banner-wrap">
      <div class="nav-bar-wrap">
        <div class="nav-item" 
          :key="index"
          @click="handleCategoryClick(item)" 
          :class="{active:selectedCategoryId==item.categoryId}"
          v-for="(item,index) in categoryList">{{item.name}}</div>
      </div>
    </div>
    <!--导航条-->
    <navigation-bar pageName="新闻动态"></navigation-bar>
    <section-title chTitle="新闻动态" enTitle="News"></section-title>

    <div class="news-body">
      <swiper class="swiper-wrap" :options="swiperOption">
        <swiper-slide :key="index" v-for="(item,index) in newsList" v-if="index < 3">
          <div class="news-swiper-item" @click="handleNewsItemClick(item)" :style="{backgroundImage: 'url(' + item.titleImgUrl + ')' }">
            <div class="news-swiper-detail">
              <div class="pub-date">Date:{{item.publishTime.substring(0,10)}}</div>
              <div class="description">{{item.cmsTitle}}</div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="pagination-wrap news-pagination-wrap">
        <div class="pagination news-pagination"></div>
      </div>

      <div class="news-list-wrap">
        <news-item :item="item" @newsClick="handleNewsItemClick" :key="index" v-if="index>2" v-for="(item,index) in newsList"></news-item>
      </div>

      <div class="flex center more-news-btn-wrap" v-if="hasMoreNews">
        <div class="learn-more-btn" @click="handleMoreNews">更多新闻</div>
      </div>

    </div>
  </div>
</template>

<script>
  import NavigationBar from '@/components/navigation-bar.vue'
  import SectionTitle from '@/components/section-title.vue'
  import NewsItem from '@/components/news-item.vue'
  import {
    Swiper,
    SwiperSlide
  } from 'vue-awesome-swiper'
  import 'swiper/css/swiper.css'

  import {
    getNewsById
  } from '@/api/news.js'

  export default {
    components: {
      NavigationBar,
      SectionTitle,
      Swiper,
      SwiperSlide,
      NewsItem
    },
    data() {
      return {
        categoryList:[
          {
            name:'公司动态',
            categoryId:152
          },
          {
            name:'行业动态',
            categoryId:153
          }
        ],
        selectedCategoryId:null,
        swiperOption: {
          autoplay: {
            disableOnInteraction: false,
            delay: 30000,
          },
          pagination: {
            el: '.news-pagination',
          },
        },
        total: 0,
        pageNo: 1,
        newsList: []
      }
    },
    created() {
      this.init()
    },
    computed: {
      hasMoreNews() {
        return this.newsList.length < this.total
      }
    },
    methods: {
      init(){
        this.selectedCategoryId = this.categoryList[0].categoryId
        this.getNewsData(this.selectedCategoryId)
      },
      getNewsData(categoryId) {
        getNewsById(categoryId,this.pageNo, 10).then(data => {
          this.selectedCategoryId = categoryId
          if (this.pageNo === 1) {
              this.newsList = []
          }
          for (let index = 0; index < data.page.list.length; index++) {
            this.newsList.push(data.page.list[index])
          }
          this.total = data.page.totalCount
        })
      },
      handleMoreNews() {
        this.pageNo = this.pageNo + 1
        this.getNewsData(this.selectedCategoryId)
      },
      handleNewsItemClick(item) {
        this.$router.push({
          path: '/news-detail',
          query: {
            newsId: item.id
          }
        })
      },
      handleCategoryClick(item){
        if(item.categoryId === this.selectedCategoryId){
          return
        }
        this.pageNo = 1
        this.getNewsData(item.categoryId)
      }
    }
  }
</script>

<style lang="scss">
  .news-page {
    
    .news-banner-wrap{
      position: relative;
      padding-top: 78.533%;
      background-image: url(../../image/news/banner2.png);
      background-repeat: no-repeat;
      background-size: 100%;
    }
    
  }

  .news-body {
    padding: 10px 12px 30px 12px;

    .news-swiper-item {
      position: relative;
      padding-top: 65.9%;
      background-size: 100%;

      .news-swiper-detail {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        height: 50px;
        background-color: rgba(0, 0, 0, .5);
        color: #ffffff;
        padding: 0px 5px;

        display: flex;
        flex-direction: column;
        justify-content: center;

        .pub-date {
          font-size: 11px;
          line-height: 20px;
        }

        .description {
          height: 20px;
          font-size: 13px;
          line-height: 20px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }

    .news-pagination-wrap {
      padding-top: 20px;
    }

    .news-list-wrap {
      margin-top: 40px;
    }

    .more-news-btn-wrap {
      margin-top: 35px;
      padding-bottom: 20px;
    }

  }
</style>
