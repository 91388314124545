<template>
  <div class="news-item-wrap" @click="handleClick">
    <div class="date">Date:{{item.publishTime.substring(0,10)}}</div>
    <div class="title">{{item.cmsTitle}}</div>
    <div class="desc">{{item.description}}</div>
  </div>
</template>

<script>
  export default {
    props:{
      item:Object
    },
    methods:{
      handleClick(){
        this.$emit('newsClick',this.item)
      }
    }
  }
</script>

<style lang="scss">
  .news-item-wrap{
    position: relative;
    margin-top: 10px;
    
    padding-bottom: 10px;
    
    .date{
      font-size: 11px;
      color:#999999;
      line-height: 20px;
    }
    .title{
      font-size: 15px;
      color:#333333;
      line-height: 26px;
    }
    .desc{
      margin-top: 8px;
      font-size: 13px;
      color:#999999;
      line-height: 20px;
    }
    
    &::after{
      position: absolute;
      width: 100%;
      content: " ";
      height: 1px;
      bottom: 0;
      left: 0;
      background-color: #c4c4c4;
      transform: scaleY(0.5);
    }
  }
  
  
  
</style>
